import React, { useRef, useEffect, useState } from 'react';
import WaveSurfer from 'wavesurfer.js';
import { PlayCircleIcon, PauseCircleIcon } from '@heroicons/react/20/solid'


const AudioWaveform = ({ audioBlob }) => {
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  useEffect(() => {
    wavesurfer.current = WaveSurfer.create({
      container: waveformRef.current,
      backend: 'WebAudio',
      waveColor: 'violet',
      progressColor: '#1DB954',
      ignoreSilenceMode: true,
    });

    if (audioBlob) {
      const audioUrl = URL.createObjectURL(audioBlob);
      wavesurfer.current.load(audioUrl);
    }

    // Listen to the finish event
    wavesurfer.current.on('finish', () => {
        setIsPlaying(false);
    });

    return () => {
      if (wavesurfer.current) {
        wavesurfer.current.destroy();
      }
    };
  }, []);

  useEffect(() => {
    if (audioBlob && wavesurfer.current) {
      const audioUrl = URL.createObjectURL(audioBlob);
      wavesurfer.current.load(audioUrl);
      setIsPlaying(false); // Reset the isPlaying state
    }
  }, [audioBlob]);

  const handlePlayPause = () => {
    if (wavesurfer.current) {
      wavesurfer.current.playPause();
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <button onClick={handlePlayPause} style={{ marginRight: '10px' }}>
        {isPlaying ? <PauseCircleIcon className="h-11 w-11" aria-hidden="true" /> : <PlayCircleIcon className="h-11 w-11" aria-hidden="true" />}
      </button>
      <div id="waveform" ref={waveformRef} style={{ flex: 1 }} />
    </div>
  );
};

export default AudioWaveform;
