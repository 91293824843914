import React, { useState, useRef, useEffect } from 'react';
import { Switch } from '@headlessui/react'
import Wavesurfer from 'wavesurfer.js';
import WaveformLoader from './WaveformLoader';

import AudioWaveform from './AudioWaveform';
import SampleWaveform from './SampleWaveform';
import Navbar from './Navbar.js';
import Samples from './Samples.js';
import InputBox from './InputBox.js';
import { useAuth0 } from '@auth0/auth0-react';

const App = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const [prompt, setPrompt] = useState('');
  const [audioSrc, setAudioSrc] = useState(null);
  const [audioKey, setAudioKey] = useState(null); // Add this state

  const [isLoading, setIsLoading] = useState(false); // Add this state for loading spinner
  const [audioBlob, setAudioBlob] = useState(null);
  const waveformRef = useRef(null);
  const wavesurfer = useRef(null);

  const handlePlay = () => {
    if (wavesurfer.current) {
      wavesurfer.current.play();
    }
  };

  const handlePause = () => {
    if (wavesurfer.current) {
      wavesurfer.current.pause();
    }
  };

  useEffect(() => {
    if (isAuthenticated && localStorage.getItem('attemptedGenerateMusic') === 'true') {
      // Remove the flag from localStorage
      localStorage.removeItem('attemptedGenerateMusic');
      // Restore the saved prompt
      const savedPrompt = localStorage.getItem('savedPrompt');
      setPrompt(savedPrompt ? savedPrompt : '');
      localStorage.removeItem('savedPrompt');

      generateMusic();
    }
  }, [isAuthenticated]);

  const generateMusic = async () => {
    if (!isAuthenticated) {
      // Save to localStorage before authenticating
      localStorage.setItem('attemptedGenerateMusic', 'true');
      localStorage.setItem('savedPrompt', prompt);

      loginWithRedirect();
      return;
    }
    
    setIsLoading(true);
    console.log("Generate music called")
    const modelInputs = {
      prompt: prompt,
      duration: 8,
    };

    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ prompt: prompt })
      }
      const response = await fetch('https://riff-backend.onrender.com/yourmom', requestOptions);
      const resp = await response.json()
      const audioBase64 = resp.audioBase64;

      const raw = window.atob(audioBase64);
      const rawLength = raw.length;
      const array = new Uint8Array(new ArrayBuffer(rawLength));

      for (let i = 0; i < rawLength; i++) {
        array[i] = raw.charCodeAt(i);
      }

      console.log("Array")
      console.log(array)
      // Create a blob from the byte array
      const audioBlob = new Blob([array], { type: 'audio/wav' });

      // Set audioSrc state
      setAudioBlob(audioBlob);
      console.log("BLOB")
      console.log(audioBlob)
    } catch (error) {
      console.error('Error generating music:', error);
    } finally {
      setIsLoading(false); // Set loading state to false once done
    }
  };

  return (
    <div>
      <Navbar />
      <div className="mx-20">
      </div>
      <div className="relative flex justify-center">
  <div className="overflow-hidden rounded-lg border border-gray-300 shadow-lg focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500 sm:mt-10 md:mt-20 md:max-w-1/2 w-full">
    <div className="p-2">
      <p className="block w-full border-0 px-3 pt-2.5 text-sm font-medium text-gray-600 focus:ring-0">Prompt</p>
      <textarea
        rows={4}
        name="description"
        id="description"
        className="block w-full resize-none border-0 py-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
        placeholder="Describe the music.. be creative"
        value={prompt}
        onChange={(e) => setPrompt(e.target.value)}
      />
      <div className="flex justify-end mt-2">
        <button
          type="submit"
          className="inline-flex items-center rounded-md bg-black m-1 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-green-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          onClick={generateMusic}
        >
          Generate
        </button>
      </div>
    </div>
  </div>
</div>

      <div className="pt-4"> {isLoading && <WaveformLoader />} </div>
      {audioSrc && (
        <audio controls key={audioKey}> {/* Set the key here */}
          <source src={audioSrc} type="audio/wav" />
          Your browser does not support the audio element.
        </audio>
      )}
      {audioBlob && (
        <AudioWaveform audioBlob={audioBlob} />
      )}
      <Samples />
      <div>
        <audio controls>
          <source src="/sample.mp3" type="audio/mp3" />
          Your browser does not support the audio element.
        </audio>
      </div>
    </div>
  );
};

export default App;
