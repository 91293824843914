import { ChevronRightIcon } from '@heroicons/react/20/solid'
import WaveSurfer from 'wavesurfer.js';

const prompts = [
  {
    name: 'Leslie Alexander',
    prompt: 'rock with saturated guitars, a heavy bass line and crazy drum break and fills.',
  },
  {
    name: 'Michael Foster',
    prompt: 'classic reggae track with an electronic guitar solo',
  },
  {
    name: 'Dries Vincent',
    prompt: 'violins and synths that inspire awe at the finiteness of life and the universe',
  },
  {
    name: 'Lindsay Walton',
    prompt: 'A dynamic blend of hip-hop and orchestral elements, with sweeping strings and brass, evoking the vibrant energy of the city.',
  },
  {
    name: 'Courtney Henry',
    prompt: 'a piano and cello duet playing a sad chambers music',
  },
  {
    name: 'Tom Cook',
    prompt: 'earthy tones, environmentally conscious, ukulele-infused, harmonic, breezy, easygoing, organic instrumentation, gentle grooves',
  },
]

export default function Example() {

  return (
    <div>
        
    <ul role="list" className="divide-y divide-gray-100">
      {prompts.map((x) => (
        <li key={x.prompt} className="relative py-5 hover:bg-gray-50">
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="mx-auto flex max-w-4xl justify-between gap-x-6">
              <div className="flex gap-x-4">
                <div className="min-w-0 flex-auto">
                  <p className="text-sm leading-6 text-gray-400">
                    prompt:
                  </p>
                  <p className="mt-1 flex text-s leading-5 text-gray-500">
                    {x.prompt}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </li>
      ))}
    </ul>
    </div>
  )
}
